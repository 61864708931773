import React,{useEffect} from "react";
import { navigate } from 'gatsby';
import jwt_decode from "jwt-decode";
import cognitoAuth from '../services/cognitoAuth'
//import LoadingOverlay from 'react-loading-overlay';
import { useQueryParam, StringParam } from "use-query-params";
import { makeStyles } from "@mui/styles";
import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// スタイルの記述をする
const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
}));


const Auth = ({location,children,setLoggedIn, user, setUser, breakpoints,loaded, setLoaded }) => {
	const classes = useStyles();
	const [token] = useQueryParam("code", StringParam);
	
	const parseCognitoWebResponse = (href) => {
		return new Promise((resolve, reject) => {
			console.log("parseCognitoWebResponse")
			// userHandler will trigger the promise
			cognitoAuth.userhandler = {
				onSuccess: function (result) {
					console.log(result)
					resolve(result)
				},
				onFailure: function (err) {
					console.log(err)
					reject(new Error('Failure parsing Cognito web response: ' + err))
				}
			}
			cognitoAuth.parseCognitoWebResponse(href)

		})
	}

	const isLoggedIn = () => {
		return cognitoAuth.signInUserSession.idToken.jwtToken !== ""
	}

	// 追加
	useEffect(() => {

		async function fetchData() {
			console.log(token)
      
			if(token !== undefined){
				var res = null
				await parseCognitoWebResponse(location.href).then(response => {
					console.log("ログイン状況", response)
					res = response
				}).catch(error => {
					console.log("ログインエラー", error)
				})

				if(res !== null){
					var params = new FormData();
					var jwt = jwt_decode(cognitoAuth.signInUserSession.idToken.jwtToken)
					params.append("name", jwt.name ?? '');
					params.append("code", jwt.sub);
					params.append("admin", jwt["custom:admin"] ?? 0);

					//送信用データを設定
					const options = {
						method: 'POST',
						mode: 'cors',
						credentials: 'omit',
						body: params,

					};
					
					console.log(jwt.name ?? '');
					console.log(jwt.sub);
					console.log(jwt["custom:admin"] ?? 0);

					// 設定したデータをPOST
					await fetch(`${process.env.GATSBY_BACKEND_URL}/users`, options)
					.then(response => response.json())
					.then(data => {
						console.log(`${data}です。`)
					})    
					.catch(error => {
						console.log(error)
					})
				}
			}

			console.log(cognitoAuth)
			console.log("isValid1"+cognitoAuth.signInUserSession.isValid())
			console.log("isValid2"+cognitoAuth.getCachedSession().isValid())
			isLoggedIn() &&(setUser({name:(jwt_decode(cognitoAuth.signInUserSession.idToken.jwtToken).name), code: (jwt_decode(cognitoAuth.signInUserSession.idToken.jwtToken).sub) }))
			isLoggedIn() &&(setLoggedIn(true))
			setLoaded(false)
    }
    
		fetchData();
	},[])

  return (
 	<>
	 	{
		 	loaded && (
				<Backdrop className={classes.backdrop} open={loaded}>
					<CircularProgress color="inherit" />
				</Backdrop>
		  )
		}

		{
			!loaded && !isLoggedIn() && (
				navigate("/")
				//console.log("redirect")
			)
		}
		{
			!loaded && isLoggedIn() && (
				<>
					<Container><div style = {{fontSize: breakpoints?.md ? 22 : 28}} >ようこそ<br />{user.name}さん</div></Container>
					{children}
				</>
			)
		}
	</>
  )
};

export default Auth;