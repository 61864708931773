import React, { useState,useEffect } from 'react';
import { navigate } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import GardensIndex from "../components/app/gardens/index"
import GardensShow from "../components/app/gardens/show"
import GrowthEdit from "../components/app/gardens/growthEdit"
import DashBoard from "../components/app/dashboard"
import Seo from "../components/seo"
import Auth from '../components/Auth'
import cognitoAuth from "../services/cognitoAuth"

const App = (props) => {
  const breakpoints = useBreakpoint();
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState({name:'', code: ''})
  const [loaded, setLoaded] = useState(true);
  const [gardenData, setGardenData] = useState([]);

  const handleLogout = () => {
    console.log("サインアウト")
    cognitoAuth.signOut();
    setLoggedIn(false)
  };


    // 追加
	useEffect(() => {
    if(props.location.pathname !== "/app/" && props.location.pathname !== "/app/prediction" && props.location.pathname !== "/app/gardens/index"){
      console.log(gardenData)
      if (gardenData.length === 0) {
  console.log("kitade")
        navigate("/app")
        return null
      }
    }
  },[gardenData])

	return(
  <Layout location={props.location} breakpoints = {breakpoints} isLoggedIn = {isLoggedIn} handleLogout = {handleLogout}>
    <Seo title="App" />
    <Auth location={props.location} setLoggedIn={setLoggedIn} user={user} setUser={setUser} breakpoints = {breakpoints} setLoaded={setLoaded} loaded={loaded}>
      <Router>
        <PrivateRoute path="/app/" component={DashBoard} user={user} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/prediction" component={DashBoard} user={user} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/gardens/index" breakpoints = {breakpoints} component={GardensIndex} user={user} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/gardens/show" breakpoints = {breakpoints} component={GardensShow} user={user} gardenData = {gardenData} setGardenData= {setGardenData} isLoggedIn = {isLoggedIn}/>
        <PrivateRoute path="/app/gardens/growthEdit" breakpoints = {breakpoints} component={GrowthEdit} user={user} gardenData = {gardenData} isLoggedIn = {isLoggedIn}/>
      </Router>
    </Auth>
    
  </Layout>
	)
}

export default App