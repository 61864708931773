import React,{useEffect,useState} from "react"
import moment from "moment"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";


export default function ApertureChart({gardenData}) {
  const [data, setData] = useState(null);
  useEffect(() => {

		async function fetchData() {
      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens/integral_aperture/${gardenData.detail[0].id}`, options)
        .then(response => response.json())
        .then(data => {
          setData(data)
          console.log(data)
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    
		fetchData();
	},[gardenData])


  return (
    <ResponsiveContainer width="95%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={t => moment(new Date(t)).format("M/D")}/>
        <YAxis />



        <Tooltip labelFormatter={t => new Date(t).toLocaleString()}/>
        <Legend />
    
        <Line dataKey="面積率" stroke="#8884d8" unit="%" />
      </LineChart>
    </ResponsiveContainer>
  );
}
