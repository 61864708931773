import React, { useEffect, useState } from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import { makeStyles } from "@mui/styles"

import { useQueryParam, StringParam } from "use-query-params"
import { Link } from "gatsby"
import IntegralTempChart from "../../integralTempChart"
import DailyMaximumTempChart from "../../dailyMaximumTempChart"
import TempChart from "../../tempChart"
import ApertureChart from "../../ApertureChart"
import ApertureBarChart from "../../ApertureBarChart"
import Title from "../../title"
import GrowthTable from "../../growthTable"
import GardenDetail from "../../gardenDetail"
import moment from "moment"
import EditIcon from "@mui/icons-material/Edit"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import AddIcon from "@mui/icons-material/Add"
import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: "5vh",
  },
  paper: {
    padding: "1vw",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: 180,
  },
  paperContent: {
    textAlign: "center",
    flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
  },
}))

const GardensShow = ({ user, gardenData, setGardenData }) => {
  const classes = useStyles()
  const [code] = useQueryParam("code", StringParam)

  const [follow, setFollow] = useState(false)
  const [latestTemp, setLatestTemp] = useState({ date: "", 気温: "" })
  const [latestCumulativeTemp, setLatestCumulativeTemp] = useState({
    date: "",
    積算気温: "",
  })
  const [latestAperture, setLatestAperture] = useState({
    date: "",
    開孔面積率: "",
  })
  const [latestMaximumTemp, setlatestMaximumTempset] = useState({
    date: "",
    "最高気温/日": "",
  })

  // 追加
  useEffect(() => {
    async function fetchData() {
      const options = {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      }

      await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens/${code}`, options)
        .then(response => response.json())
        .then(data => {
          setGardenData(data[0])
          setLatestTemp(data[0].detail[0].current_temperature)
          data[0].detail[0].current_cumulative_temperature &&
            setLatestCumulativeTemp(
              data[0].detail[0].current_cumulative_temperature
            )
          data[0].detail[0].current_aperture &&
            setLatestAperture(data[0].detail[0].current_aperture)
          data[0].detail[0].current_maximum_temperature &&
            setlatestMaximumTempset(
              data[0].detail[0].current_maximum_temperature
            )
          console.log(data[0])
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })

      await fetch(
        `${process.env.GATSBY_BACKEND_URL}/gardens/${code}/follow?user_code=${user.code}`,
        options
      )
        .then(response => response.json())
        .then(data => {
          console.log(data[0][0]["enabled"])
          setFollow(data[0][0]["enabled"] === 1)
        })
        .catch(error => {
          console.log(error)
        })
    }

    fetchData()
  }, [code])

  const onCliedFollow = async () => {
    const newFollow = !follow

    let params = new FormData()
    params.append("user_code", user.code)
    params.append("garden_id", gardenData.id)
    params.append("enabled", newFollow)

    let url = `${process.env.GATSBY_BACKEND_URL}/gardens/follow`

    for (let value of params.entries()) {
      console.log(value)
    }
    await fetch(url, {
      method: "POST",
      mode: "cors",
      body: params,
    })
      .then(() => {
        setFollow(newFollow)
        alert("登録しました。")
      })
      .catch(error => alert(error))
  }

  return (
    <Container className={classes.container}>
      <Link
        to="/app/gardens/index"
        style={{ float: "right", fontSize: "16px" }}
      >
        戻る
      </Link>
      <h1>
        {gardenData?.name}
        <IconButton onClick={onCliedFollow}>
          {!follow && <FavoriteBorderIcon />}
          {follow && <FavoriteIcon style={{ color: "red" }} />}
        </IconButton>
      </h1>

      <Grid container spacing={3}>
        <Grid item xs={6} md={2}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "honeydew" }}
          >
            <Title>直近の気温</Title>
            <p className={classes.paperContent}>
              <div style={{ color: "green", fontSize: 32 }}>
                <strong>{latestTemp["気温"]}℃</strong>
              </div>
              <div style={{ fontSize: 12 }}>
                {" "}
                {moment(latestTemp.date).format("M/D H:mm")} 現在
              </div>
            </p>
            <div>
              <a
                href={`https://sv100.iot.nmt.jp/d/eQXL1bF7z/agri-support-system-for-carrots-r1`}
                rel="noreferrer"
                target="_blank"
                style={{
                  paddingLeft: 5,
                  textDecoration: "none",
                  paddingRight: 10,
                }}
              >
                詳細(grafana)
              </a>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "aliceblue" }}
          >
            <Title>積算気温</Title>
            <p className={classes.paperContent}>
              <div style={{ color: "blue", fontSize: 32 }}>
                <strong>{latestCumulativeTemp["積算気温"]}℃</strong>
              </div>
              <div style={{ fontSize: 12 }}>
                {" "}
                {moment(latestCumulativeTemp.date).format("M/D H:mm")} 現在
              </div>
            </p>
            <div>
              <Link color="primary" href="#">
                詳細
              </Link>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "honeydew" }}
          >
            <Title>開孔面積率</Title>
            <p className={classes.paperContent}>
              <div style={{ color: "green", fontSize: 32 }}>
                <strong>{latestAperture["開孔面積率"]}%</strong>
              </div>
              <div style={{ fontSize: 12 }}>
                {" "}
                {moment(latestAperture.date).format("M/D H:mm")} 現在
              </div>
            </p>
            <div>
              <Link color="primary" href="#">
                詳細
              </Link>
              <a
                href={`https://www.data.jma.go.jp/gmd/cpd/twoweek/?fuk=71`}
                rel="noreferrer"
                target="_blank"
                style={{
                  paddingLeft: 5,
                  textDecoration: "none",
                  paddingRight: 10,
                  float: "right",
                }}
              >
                2週間気温予報
              </a>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "aliceblue" }}
          >
            <Title>今日の最高気温</Title>
            <p className={classes.paperContent}>
              <div style={{ color: "blue", fontSize: 32 }}>
                <strong>{latestMaximumTemp["最高気温"]}℃</strong>
              </div>
              <div style={{ fontSize: 12 }}>
                {" "}
                {moment(latestMaximumTemp.date).format("M/D H:mm")} 現在
              </div>
            </p>
            <div></div>
          </Paper>
        </Grid>

        <Grid item xs={6} md={2}>
          <Paper className={classes.paper}>
            <Title>育成記録</Title>
            <p className={classes.paperContent}>
              <div>
                <Link to="/app/gardens/growthEdit">
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    startIcon={<EditIcon />}
                  >
                    記録を書く
                  </Button>
                </Link>
              </div>
              <div style={{ paddingTop: 10 }}>
                <Link href="#growth_record">
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    startIcon={<FormatListBulletedIcon />}
                  >
                    履歴を見る
                  </Button>
                </Link>
              </div>
            </p>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>気温グラフ(℃)</Title>
            {gardenData.detail && (
              <TempChart code={gardenData.detail[0].sensor_code} />
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Title>最高気温/日グラフ(℃)</Title>
            {gardenData.detail && (
              <DailyMaximumTempChart code={gardenData.detail[0].sensor_code} />
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <GardenDetail gardenData={gardenData} temp={latestCumulativeTemp} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>積算気温グラフ(℃)</Title>
              {gardenData.detail && (
                <IntegralTempChart gardenData={gardenData} />
              )}
            </Paper>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>開孔面積率グラフ(%)</Title>
              {gardenData.detail && <ApertureChart gardenData={gardenData} />}
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <p>
              <Title>換気モデル</Title>
            </p>
            <ApertureBarChart />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Paper id="growth_record" className={classes.paper}>
            <div style={{ display: "flex" }}>
              <Title>生育記録</Title>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginLeft: "auto",
                }}
              >
                <Link to="/app/gardens/growthEdit">
                  <IconButton
                    style={{ backgroundColor: "#9c27b0", color: "white" }}
                    aria-label="add"
                  >
                    <AddIcon style={{ fontSize: 16 }} />
                  </IconButton>
                </Link>
              </div>
            </div>
            {gardenData.detail && <GrowthTable gardenData={gardenData} />}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
export default GardensShow
