import React from "react"
import { Link } from 'gatsby';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { makeStyles} from "@mui/styles";
import moment from "moment"
import { flexbox } from "@mui/system";

const useStyles = makeStyles(() => ({
	paper: {
		backgroundColor: "white",//theme.palette.background.paper,
		position: "relative",
		border: '2px solid #000',
		padding: "28px 5vw 5vh 5vw",
		minWidth: "30vw",
	},
	modal: {
	  display: 'grid',
	  alignItems: 'center',
	  justifyContent: 'center',
		padding: "5vw",
		overflow:'scroll',
		
	},
}))

const GrowthRecordModal = ({open, onClose, record}) => {
	const classes = useStyles();

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={ onClose }
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
					timeout: 500,
			}}
		>
			<Fade in={open}>
			{record && (
				<div className={classes.paper}>	
					<div style={{display: "flex"}}>
						<h2>{moment(record.date).format("YYYY年MM月DD日")}</h2>
						<div style={{display: "flex",alignItems: "center", marginLeft: "auto"}}>
							<Link to={`/app/gardens/growthEdit?garden_detail_id=${record.garden_detail_id}&growth_record_id=${record.id}`}>
								<IconButton style={{backgroundColor: "#9c27b0", color: "white"}} aria-label="add">
									<EditIcon />
								</IconButton>
							</Link>
						</div>
					</div>
					<div style={{paddingLeft: "5vw", paddingRight: "5vw",minHeight: "25vh"}}>
						{record.image_name && (<img id="cropImage" alt={record.image_name} style={{ maxHeight: '50vh',maxWidth: "100%" }} src={`${process.env.GATSBY_BACKEND_URL}/images/growth_records/${record.image_name}`} />)}
					</div>
					<List dense={true}>
						<ListItem>
							<ListItemText	primary={<div style={{minWidth: 100}}>備考</div>}/>
							{
								record.note.split('\n').map((str, index) => (<React.Fragment key={index}>{str}<br /></React.Fragment>))
							}
						</ListItem>
						<ListItem>
							<ListItemText	primary="開口面積（m2）" />{record.aperture}
						</ListItem>
						{Object.keys(record.hole_state).map(key => (
              <ListItem>
								<ListItemText	primary={`${key.replace("num","")}cm穴`} />{record.hole_state[key]["span"]}スパンで{record.hole_state[key]["hole"]}個
							</ListItem>	
            ))}
					</List>

				</div>
			)}
			</Fade>
		</Modal>
	)
}
export default GrowthRecordModal