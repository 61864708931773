import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Title from "./title"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'

const GardenDetail = ({gardenData,temp}) => {

	const diff = gardenData.detail ? moment().diff(moment(gardenData.detail[0]?.planted_at),'days') : 0
	const cumul_tmp = temp ? Number(temp["積算気温"]) : null
	const harvested_at = cumul_tmp ? moment().add((gardenData.detail[0]?.target_temperature / cumul_tmp -1 ) * diff , "days"): null

	return (
		<Accordion defaultExpanded style={{display: "block", height: "100%"}}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Title>圃場詳細</Title>
				</AccordionSummary>
				<AccordionDetails style={{display: "block"}}>
				<div style={{textAlign: "center"}}><img src={`${process.env.GATSBY_BACKEND_URL}/images/gardens/${gardenData.image_name}`} alt={"image1"} style={{height: 200,maxWidth: 300}}/></div>
				<List dense={true}>
					<ListItem>
						<ListItemText	primary="圃場名" />{gardenData.name}
					</ListItem>
					<ListItem>
						<ListItemText	primary="栽培者" />{gardenData.farmer}
					</ListItem>
					<ListItem>
						<ListItemText	primary="作付面積" />{gardenData.area}a
					</ListItem>
					<ListItem>
						<ListItemText	primary="品種" />{gardenData.detail && gardenData.detail[0]?.variety}
					</ListItem>
					<ListItem>
						<ListItemText	primary="目標積算気温" />{gardenData.detail && gardenData.detail[0]?.target_temperature}℃
					</ListItem>
					<ListItem>
						<ListItemText	primary="播種日" />{gardenData.detail && moment(gardenData.detail[0]?.planted_at).format("YYYY年MM月DD日")}
					</ListItem>
					<ListItem>
						<ListItemText	primary="経過日数" />{diff}日
					</ListItem>
					<ListItem>
						<ListItemText	primary="予想収穫開始日" />{harvested_at && harvested_at.format("YYYY年MM月DD日")}
					</ListItem>
					<ListItem>
						<ListItemText	primary="トンネル長さ" />{gardenData.detail && gardenData.detail[0]?.tunnel_length}m
					</ListItem>
					<ListItem>
						<ListItemText	primary="トンネル開口" />{gardenData.detail && gardenData.detail[0]?.tunnel_aperture}m
					</ListItem>
					<ListItem>
						<ListItemText	primary="支柱間隔" />{gardenData.detail && gardenData.detail[0]?.span}m
					</ListItem>
					<ListItem>
						<ListItemText	primary="センサーコード" />{gardenData.detail && gardenData.detail[0]?.sensor_code}
					</ListItem>
				</List>    
				</AccordionDetails>
			</Accordion>
	)
}

export default GardenDetail