import React,{useEffect,useState} from 'react';
import moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer
} from "recharts";

export default function TempChart({code}) {

    const [tempData, setTempData] = useState();

    // 追加
      useEffect(() => {
        async function fetchData() {
					const options = {
						method: 'GET',
						mode: 'cors',
						credentials: 'omit',
						headers: {
							'Accept': 'application/json, text/plain, */*',
						},
					};
		
					await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens/sensors/${code}`, options)
						.then(response => response.json())
						.then(data => {
							setTempData(data)
						})    
						.catch(error => {
							console.log(error)
							alert(error)
						})
      	}
        code && fetchData();
      },[code])

  return (
		<>
			<ResponsiveContainer width="100%" height={300}>
				<LineChart
					data={tempData}
					tickFormatter={(unixTime) => moment(new Date(unixTime)).toLocaleDateString()}
					margin={{
						top: 20,
						right: 0,
						left: 0,
						bottom: 5
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="date" tickFormatter={t => moment(new Date(t)).format("M/D h:mm")}/>
					<YAxis />
					<Tooltip labelFormatter={t => new Date(t).toLocaleString()}/>
					<Line type="monotone" dataKey="気温" stroke="#191970" animationDuration={1000} unit="℃" strokeWidth={3} fill="#8884d8"/>
					<ReferenceLine y={30} label={ {position: 'bottom', value: "30℃"}} stroke="red" />
				</LineChart>
			</ResponsiveContainer>
		</>
  );
}
