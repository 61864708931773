import React ,{useEffect,useState} from "react"
import moment from "moment"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

export default function IntegralTempChart({gardenData}) {
  const [data, setData] = useState(null);
  useEffect(() => {

		async function fetchData() {
      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens/integral_temperature/${gardenData.detail[0].sensor_code}`, options)
        .then(response => response.json())
        .then(data => {
          setData(data)
          console.log(data)
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    
		fetchData();
	},[gardenData])


  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 5,
          left: 5,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={t => moment(new Date(t)).format("M/D h:mm")}/>
        
        <YAxis yAxisId="left" domain={[0, 1500]} label={{ value: "積算気温（℃）", angle: -90, dx: -23 }} />
        <YAxis yAxisId="right" orientation="right" label={{ value: "平均気温（℃）", angle: -90, dx: 15 }} />
  
        <Tooltip labelFormatter={t => new Date(t).toLocaleString()}/>
        <Legend />
 {/*       <ReferenceLine x="2022/1/31" stroke="red" label={ {position: 'right', value: "現在地" }}/> 
        <ReferenceLine y={9800} label={ {position: 'bottom', value: "最大値"}} stroke="red" /> */}
    
        <Line yAxisId="left" type="monotone" dataKey="積算気温" stroke="#8884d8" fill="#8884d8" unit="℃" strokeWidth={2} />
        <Line yAxisId="right" type="monotone" dataKey="平均気温" stroke="#82ca9d" fill="#82ca9d" unit="℃" strokeWidth={2} />
        
      </LineChart>
    </ResponsiveContainer>
  );
}
