import React from "react"
import Container from '@mui/material/Container';
import { Link } from 'gatsby';
import TitlebarImageList from "../../gardenList";

const GardensIndex = ({user,breakpoints}) => (
  <Container>
    <Link to="/app/prediction" style={{float: "right", fontSize: "16px"}}>戻る</Link>
    <h1>圃場一覧</h1>
    <TitlebarImageList breakpoints={breakpoints}/>
  </Container>
)
export default GardensIndex