import React,{useEffect,useState} from 'react';
import { makeStyles } from '@mui/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'gatsby';
import Button from '@mui/material/Button';


const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: "3vh",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "white",
  },
  imageList: {
    width: "100%",
    height: "60vh",
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

export default function TitlebarImageList({breakpoints}) {
  const classes = useStyles();
  const [gardensData, setGeardensData] = useState([]);
  const cols = breakpoints.mobile ? 2 : 5;

  // 追加
	useEffect(() => {

		async function fetchData() {

      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens`, options)
        .then(response => response.json())
        .then(data => {
          setGeardensData(data)
          console.log(`${JSON.stringify(data)}`)
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    
		fetchData();
	},[])


  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList} cols={cols}>
        <ImageListItem key="Subheader" cols={cols} style={{ height: 'auto' }}>
          <ListSubheader component="div">December</ListSubheader>
        </ImageListItem>
        {gardensData.map((garden) => (
          <ImageListItem key={garden.img}>
            <Link to={`/app/gardens/show?code=${garden.code}`}>
              <Button style={{width: "100%", display: "contents",textTransform: "none"}}>
                <img src={`${process.env.GATSBY_BACKEND_URL}/images/gardens/${garden.image_name}`} alt={garden.image_name}  style={{width: "100%", height: 180, filter: garden.status===1? "grayscale(0%)":"grayscale(70%)"}} />
                <ImageListItemBar
                  title={garden.name}
                  style={{textAlign: "left"}}
                  subtitle={<span>by: {garden.farmer}</span>}
                  actionIcon={
                    <IconButton aria-label={`info about ${garden.name}`} className={classes.icon}>  
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </Button>
            </Link>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}