import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import { useForm, Controller } from "react-hook-form"
import { makeStyles } from "@mui/styles"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import PhotoCamera from "@mui/icons-material/PhotoCamera"
import Paper from "@mui/material/Paper"
import Popover from "@mui/material/Popover"
import HelpIcon from "@mui/icons-material/Help"

import ja from "moment/locale/ja"
import DateAdapter from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"
import moment from "moment"

import SpanHoleEdit from "../../spanHoleEdit"
import Resizer from "react-image-file-resizer"

// スタイルの記述をする
const useStyles = makeStyles(() => ({
  content: {
    marginTop: 30,
    display: "grid",
  },

  form: {
    marginTop: 50,
    marginBottom: 100,
  },
  error: {
    color: "#f44336",
    fontSize: 14,
  },
  checkGroup: {
    marginLeft: 20,
    marginRight: 2,
  },
}))

const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      100,
      0,
      uri => {
        resolve(uri)
      },
      "base64"
    )
  })

const Error = ({ arg, className }) => {
  return (
    arg !== undefined && (
      <p className={className} key={arg.type}>
        {arg.message}
      </p>
    )
  )
}

const GrowthEdit = ({ user, gardenData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    setValue,
  } = useForm()
  const [errorMessage, setErrorMessage] = useState("")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [garden_detail_id] = useQueryParam("garden_detail_id", StringParam)
  const [growth_record_id] = useQueryParam("growth_record_id", StringParam)
  const classes = useStyles()
  const [count, setCount] = useState(0) // holeState変更カウント
  const [holeState, setHoleState] = useState({
    num8: { span: 1, hole: 0 },
    num6: { span: 1, hole: 0 },
    num12: { span: 1, hole: 0 },
    num10: { span: 1, hole: 0 },
  })
  const [aperture, setAperture] = useState(0)
  const [selectedFile, setSelectedFile] = useState("")
  const [blobFile, setBlobFile] = useState()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  // 開口面積の値が負になっているかのバリデーション
  const validAperture = aperture => {
    if (aperture < 0 && errorMessage !== "開孔面積が0未満です！") {
      setErrorMessage("開孔面積が0未満です！")
      setIsSubmitDisabled(true)
    } else if (aperture >= 0 && errorMessage !== "") {
      setErrorMessage("")
      setIsSubmitDisabled(false)
    }
  }

  const onSubmit = async data => {
    let myForm = document.getElementById("growth_edit_form")
    let params = new FormData(myForm)

    params.append("holeState", JSON.stringify(holeState))
    params.append("aperture", aperture)
    params.append("garden_detail_id", gardenData.detail[0].id)
    params.append("recordDate", data.recordDate.format("YYYY/MM/DD"))
    params.delete("growthImage")
    blobFile && params.append("growthImage", blobFile, "image.jpeg")
    growth_record_id && params.append("id", growth_record_id)
    let url = growth_record_id
      ? `${process.env.GATSBY_BACKEND_URL}/growth_records/${growth_record_id}`
      : `${process.env.GATSBY_BACKEND_URL}/growth_records`

    for (let value of params.entries()) {
      //   console.log("value: ", value)
    }
    await fetch(url, {
      method: "POST",
      mode: "cors",
      body: params,
    })
      .then(() => alert("登録しました。"))
      .catch(error => alert(error))
    navigate(`/app/gardens/show?code=${gardenData.code}`)
  }

  const onUploadImage = async e => {
    const image = await resizeFile(e.target.files[0])
    fetch(image)
      .then(res => res.blob())
      .then(blob => {
        setBlobFile(blob)
        var url = URL.createObjectURL(blob)
        setSelectedFile(url)
      })
  }

  const handleChangeHoleState = e => {
    console.log(e.target.value)
    const name = e.target.name.match(/(span|hole)(.*)/)
    console.log(name)

    var tmp = holeState
    tmp[`num${name[2]}`][`${name[1]}`] = Number(e.target.value)
    setHoleState(tmp)

    let sum = 0
    for (let key in tmp) {
      let radius = Number(key.match(/num(.*)/)[1]) / 2
      sum +=
        (radius * radius * 3.14 * Number(tmp[key]["hole"])) /
        Number(tmp[key]["span"])
      console.log(
        (radius * radius * 3.14 * Number(tmp[key]["hole"])) /
          Number(tmp[key]["span"])
      )
    }
    setAperture(sum)
    validAperture(sum)

    // jsonや配列は中身が変更されてもレンダリングされないので別変数を使用して回避する
    setCount(count + 1)
  }

  useEffect(() => {
    async function fetchData() {
      const options = {
        method: "GET",
        mode: "cors",
        credentials: "omit",
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      }

      await fetch(
        `${process.env.GATSBY_BACKEND_URL}/growth_records/${garden_detail_id}?record_id=${growth_record_id}`,
        options
      )
        .then(response => response.json())
        .then(data => {
          setHoleState(data[0].hole_state)
          setAperture(data[0].aperture)
          setValue("recordDate", moment(data[0].date))
          setValue("note", data[0].note)
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    if (growth_record_id !== undefined) {
      fetchData()
    }
  }, [garden_detail_id, growth_record_id])

  const growthImageRegister = register("growthImage")
  !getValues("recordDate") && setValue("recordDate", moment())

  return (
    <Container>
      <Link
        to={`/app/gardens/show?code=${gardenData.code}`}
        style={{ float: "right", fontSize: "16px" }}
      >
        戻る
      </Link>
      <h1>育成記録[{gardenData.name}]</h1>
      <h3>※ 穴サイズの配置を変更してます、入力の際はご注意ください</h3>

      <form
        id="growth_edit_form"
        name="growth_edit"
        method="POST"
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.content}>
          <LocalizationProvider dateAdapter={DateAdapter} local={ja}>
            <Controller
              control={control}
              rules={{
                required: "必須入力です",
                validate: {
                  message: () =>
                    getValues("recordDate")?.format() !== "Invalid date"
                      ? null
                      : "不正な日付です。",
                },
              }}
              name="recordDate"
              defaultValue={getValues("recordDate")}
              render={props => (
                <DatePicker
                  label="記録日"
                  value={getValues("recordDate")}
                  onChange={e => {
                    props.field.onChange(e)
                  }}
                  renderInput={params => (
                    <TextField {...params} error={Boolean(errors.recordDate)} />
                  )}
                />
              )}
            />
            <Error className={classes.error} arg={errors.recordDate} />
          </LocalizationProvider>
        </div>

        <Paper style={{ marginTop: 30, padding: 15 }}>
          <div style={{ display: "inline-flex", marginBottom: 15 }}>
            <span style={{ fontSize: "18px" }}>
              {" "}
              開孔面積：{aperture} ㎠&nbsp;
              <input
                type="file"
                accept="image/*"
                hidden
                {...growthImageRegister}
                onChange={e => {
                  growthImageRegister.onChange(e)
                  onUploadImage(e)
                }}
              />
            </span>
            <HelpIcon
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              aria-label="add"
            />
            {errorMessage && (
              <div style={{ color: "red", marginTop: 5 }}>{errorMessage}</div>
            )}
          </div>
          <div>
            <Popover
              id="mouse-over-popover"
              sx={{ pointerEvents: "none" }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div style={{ margin: 10 }}>
                ※注意
                <br />
                <strong style={{ color: "red" }}>当日の開孔した個数</strong>
                を入力してください。
                <br />
                既に空いている穴を入力する必要はありません。
              </div>
            </Popover>
          </div>
          <Grid container spacing={2}>
            {Object.keys(holeState).map(key => (
              <Grid item xs={12} md={6}>
                <SpanHoleEdit
                  spanHole={key.replace("num", "")}
                  hole={holeState[key]["hole"]}
                  span={holeState[key]["span"]}
                  register={register}
                  errors={errors}
                  handleChange={handleChangeHoleState}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>

        <div className={classes.content}>
          <TextField
            id="outlined-note"
            label="備考"
            variant="outlined"
            error={Boolean(errors.note)}
            {...register("note", {
              maxLength: { value: 1000, message: "最大文字数は1000文字です" },
            })}
            multiline
            rows={4}
            rowsMax={8}
          />
          <Error className={classes.error} arg={errors.note} />
        </div>
        <div className={classes.content}>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            style={{ width: 50 }}
          >
            <PhotoCamera />
            <input
              type="file"
              accept="image/*"
              hidden
              {...growthImageRegister}
              onChange={e => {
                growthImageRegister.onChange(e)
                onUploadImage(e)
              }}
            />
          </Button>

          {selectedFile && (
            <img alt="growthImage" width="100%" src={selectedFile} />
          )}
        </div>
        <div className={classes.content}>
          <Button
            style={{ fontSize: "18px" }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitDisabled}
          >
            送信
          </Button>
        </div>
      </form>
    </Container>
  )
}
export default GrowthEdit
