import React from "react";
import Chart from "react-google-charts";
const year = new Date().getYear()+1900;

const data = [
  [
    { type: 'string', id: 'Room' },
    { type: 'string', id: 'Name' },
    { type: 'date', id: 'Start' },
    { type: 'date', id: 'End' },
    { type: 'string', role: 'tooltip' },
  ],

  // 10/05〜10/15
  // [
  //   '10/05〜10/15播種',
  //   '0.5%',
  //   new Date(year, 9, 5),
  //   new Date(year, 11, 1, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '1%',
  //   new Date(year, 11, 1, 0, 0, 0),
  //   new Date(year, 11, 10, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '2%',
  //   new Date(year, 11, 10, 0, 0, 0),
  //   new Date(year, 11, 25, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '2.5%',
  //   new Date(year, 11, 25, 0, 0, 0),
  //   new Date(year+1, 1, 10, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '3%',
  //   new Date(year+1, 1, 10, 0, 0, 0),
  //   new Date(year+1, 2, 1, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '5%',
  //   new Date(year+1, 2, 1, 0, 0, 0),
  //   new Date(year+1, 2, 15, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],
  // [
  //   '10/05〜10/15播種',
  //   '6%',
  //   new Date(year+1, 2, 15, 0, 0, 0),
  //   new Date(year+1, 2, 20, 0, 0, 0),
  //   "Status: <br>some more stuff here!!!"
  // ],

  // 10/15〜10/25
  [
    '10/15〜10/25播種',
    '0.3%',
    new Date(year, 9, 15),
    new Date(year, 11, 1),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/15〜10/25播種',
    '0.5%',
    new Date(year, 11, 1, 0, 0, 0),
    new Date(year, 11, 25, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/15〜10/25播種',
    '1%',
    new Date(year, 11, 25, 0, 0, 0),
    new Date(year+1, 2, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/15〜10/25播種',
    '2%',
    new Date(year+1, 2, 1, 0, 0, 0),
    new Date(year+1, 2, 15, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/15〜10/25播種',
    '4%',
    new Date(year+1, 2, 15, 0, 0, 0),
    new Date(year+1, 2, 31, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],

  // 10/25〜11/10
  [
    '10/25〜11/10播種',
    '0.2%',
    new Date(year, 9, 25, 0, 0, 0),
    new Date(year, 11, 25, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '0.3%',
    new Date(year, 11, 25, 0, 0, 0),
    new Date(year+1, 0, 25, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '0.5%',
    new Date(year+1, 0, 25, 0, 0, 0),
    new Date(year+1, 1, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '1%',
    new Date(year+1, 1, 10, 0, 0, 0),
    new Date(year+1, 2, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '2%',
    new Date(year+1, 2, 1, 0, 0, 0),
    new Date(year+1, 2, 15, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '4%',
    new Date(year+1, 2, 15, 0, 0, 0),
    new Date(year+1, 2, 31, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],

  // 11/10〜11/20
  [
    '11/10〜11/20播種',
    '0.1%',
    new Date(year, 10, 10, 0, 0, 0),
    new Date(year, 11, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '0.2%',
    new Date(year, 11, 10, 0, 0, 0),
    new Date(year+1, 0, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '0.3%',
    new Date(year+1, 0, 10, 0, 0, 0),
    new Date(year+1, 0, 25, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '0.5%',
    new Date(year+1, 0, 25, 0, 0, 0),
    new Date(year+1, 1, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '1%',
    new Date(year+1, 1, 10, 0, 0, 0),
    new Date(year+1, 2, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '2%',
    new Date(year+1, 2, 1, 0, 0, 0),
    new Date(year+1, 2, 15, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '4%',
    new Date(year+1, 2, 15, 0, 0, 0),
    new Date(year+1, 3, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '5%',
    new Date(year+1, 3, 1, 0, 0, 0),
    new Date(year+1, 3, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '6%',
    new Date(year+1, 3, 10, 0, 0, 0),
    new Date(year+1, 3, 20, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '8%',
    new Date(year+1, 3, 20, 0, 0, 0),
    new Date(year+1, 3, 30, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],

  // 11/20〜12/31
  [
    '11/20〜12/31播種',
    '0.1%',
    new Date(year, 10, 20, 0, 0, 0),
    new Date(year+1, 0, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '0.2%',
    new Date(year+1, 0, 10, 0, 0, 0),
    new Date(year+1, 0, 25, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '0.5%',
    new Date(year+1, 0, 25, 0, 0, 0),
    new Date(year+1, 1, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '1%',
    new Date(year+1, 1, 10, 0, 0, 0),
    new Date(year+1, 2, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '2%',
    new Date(year+1, 2, 1, 0, 0, 0),
    new Date(year+1, 2, 15, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '4%',
    new Date(year+1, 2, 15, 0, 0, 0),
    new Date(year+1, 3, 1, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '5%',
    new Date(year+1, 3, 1, 0, 0, 0),
    new Date(year+1, 3, 10, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '6%',
    new Date(year+1, 3, 10, 0, 0, 0),
    new Date(year+1, 3, 20, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '8%',
    new Date(year+1, 3, 20, 0, 0, 0),
    new Date(year+1, 3, 30, 0, 0, 0),
    "Status: <br>some more stuff here!!!"
  ],

  // 播種期間
  // [
  //   '10/05〜10/15播種',
  //   '播種期間',
  //   new Date(year, 9, 5),
  //   new Date(year, 9, 15),
  //   "Status: <br>some more stuff here!!!"
  // ],
  [
    '10/15〜10/25播種',
    '播種期間',
    new Date(year, 9, 15),
    new Date(year, 9, 25),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '10/25〜11/10播種',
    '播種期間',
    new Date(year, 9, 25),
    new Date(year, 10, 10),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/10〜11/20播種',
    '播種期間',
    new Date(year, 10, 10),
    new Date(year, 10, 20),
    "Status: <br>some more stuff here!!!"
  ],
  [
    '11/20〜12/31播種',
    '播種期間',
    new Date(year, 10, 20),
    new Date(year, 11, 31),
    "Status: <br>some more stuff here!!!"
  ]

]

const ApertureBarChart = () => {

  return (
    <div style={{overflowX: "auto",overflowY: "hidden"}}>
    <Chart
      chartType="Timeline"
      style={{minWidth: 600, height: 420}}
      loader={<div>Loading Chart</div>}
      data={data}
      options={{
        hAxis: {
          format: 'M月'
        },
        allowHtml: true,
      }}
      rootProps={{ 'data-testid': '5' }}
    /></div>
  );
};
export default ApertureBarChart