import React from "react"
import TextField from "@mui/material/TextField"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"

const SpanHoleEdit = ({
  spanHole,
  register,
  errors,
  handleChange,
  span,
  hole,
}) => {
  const spanRegister = register(`span${spanHole}`, {
    required: "必須入力です",
    min: { value: 1, message: "下限は0です" },
    max: { value: 10, message: "上限は10です" },
  })
  const holeRegister = register(`hole${spanHole}`, {
    required: "必須入力です",
    min: { value: -10, message: "下限は-10です" },
    max: { value: 10, message: "上限は10です" },
  })

  return (
    <FormControl
      component="fieldset"
      style={{ marginTop: 30, paddingLeft: 11 }}
    >
      <FormLabel component="legend" style={{ paddingBottom: 8 }}>
        {`${spanHole}`}cm開孔
      </FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <TextField
              id={`span${spanHole}`}
              label="スパン数"
              variant="outlined"
              value={span}
              InputProps={{ inputProps: { min: 1, max: 10, step: 1 } }}
              type="number"
              style={{ minWidth: 90, paddingRight: 5 }}
              error={Boolean(errors[`span${spanHole}`])}
              {...spanRegister}
              onChange={e => {
                spanRegister.onChange(e)
                handleChange(e)
              }}
            />
          }
          label="スパンで"
        />
        <FormControlLabel
          control={
            <TextField
              id={`hole${spanHole}`}
              label="穴数"
              variant="outlined"
              value={hole}
              InputProps={{ inputProps: { min: -10, max: 10, step: 1 } }}
              type="number"
              style={{ minWidth: 90, paddingRight: 5 }}
              error={Boolean(errors[`hole${spanHole}`])}
              {...holeRegister}
              onChange={e => {
                holeRegister.onChange(e)
                handleChange(e)
              }}
            />
          }
          label="個"
        />
      </FormGroup>
    </FormControl>
  )
}

export default SpanHoleEdit
