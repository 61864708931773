import React,{useEffect,useState} from "react"
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import moment from 'moment'

import GrowthRecordModal from "./growthRecordModal";


const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
  growth_image: {
    aspectRatio: 3 / 2,
    objectFit: "cover",
    width: "100%" 
  }
});

export default function GrowthTable({gardenData}) {
  const classes = useStyles();
  const [records, setRecords] = useState(null);
  const [record, setRecord] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (index) => {
    //var record = records[e.currentTarget.value]
    console.log(index)
    setRecord(records[index])
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

	useEffect(() => {

		async function fetchData() {
      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_BACKEND_URL}/growth_records/${gardenData.detail[0].id}`, options)
        .then(response => response.json())
        .then(data => {
          setRecords(data)
          console.log(data)
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
    
		fetchData();
	},[gardenData])

console.log(`records : ${JSON.stringify(records)}`)
  return (
    <TableContainer component={Paper}>

      <GrowthRecordModal 
        open= {open}
        onClose={handleClose} 
        record={record}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell >開口面積（当日分）</TableCell>
            <TableCell >備考</TableCell>
            <TableCell >画像</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records && records.map((record,index) => (
            <TableRow hover key={record.id} onClick={() => { handleOpen(index) }} >
              <TableCell component="th" scope="row">
                {moment(record.date).format("YYYY/MM/DD")}
              </TableCell>
              <TableCell >{record.aperture}</TableCell>
              <TableCell >
                <div style={{ width: "40vw", whiteSpace: 'nowrap'}}>
                  <Box component="div" textOverflow="ellipsis" overflow="hidden">
                    {record.note}
                  </Box>
                </div> 
              </TableCell>
              <TableCell >{record.image_name && (
                <img 
                  src={`${process.env.GATSBY_BACKEND_URL}/images/growth_records/${record.image_name}`} 
                  alt={record.image_name} style={{height: 50, width: "100%", minWidth: 50}}
                  className={classes.growth_image}/>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
