import React from "react";
import { Link } from 'gatsby';
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import GoogleMapReact from "../map"
import Select from "../select"

// スタイルの記述をする
const useStyles = makeStyles(() => ({
  prediction: {
    paddingBottom: "5vh"
  },
  content: {
    marginTop: 30, 
    display: 'grid'
  },
  form: {
    marginTop: 50,
    marginBottom: 100,
  },
  error: {
    color: "#f44336"
  },
  checkGroup: {
    marginLeft: 20,
    marginRight: 2
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
  
}));

const Dashboard = () => {
  const classes = useStyles();
  return (

    <div className={classes.prediction}>
      <Container>
        {//<Select/>
        }
        <h2 style={{paddingTop: "2vh"}} >
          <Link to="/app/gardens/index" style={{float: "right", fontSize: "16px"}}>圃場一覧</Link>
          ダッシュボード
        </h2>
        <GoogleMapReact />
      </Container>
    </div>
  )
};

export default Dashboard;