import React,{useEffect,useState} from 'react';
import ReactDOM from 'react-dom'
import GoogleMapReact from 'google-map-react'
import { faCarrot } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'gatsby';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {makeStyles} from "@mui/styles";
import moment from 'moment'

const defaultProps = {
  center: {
    lat: 34.06480397871603,
    lng: 134.5607477716647,
  },
  zoom: 15,
}

const useStyles = makeStyles(() => ({
  paper: {
		height: "100%",
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperContent: {
    textAlign: "center",   
    flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
  }
}));

const MarkerDom = ({garden}) => {
	const classes = useStyles();
	const paperHight = 100
	return(
	<div id="content">
    <div id="siteNotice"></div>
		<Link to={`/app/gardens/show?code=${garden.code}`}><Button style={{fontSize: 16, float: "right"}} variant="contained" color="primary">詳細</Button></Link>
		<h1 id="firstHeading">圃場情報</h1>
		<div style={{textAlign: "center"}}>
			<img src={`${process.env.GATSBY_BACKEND_URL}/images/gardens/${garden.image_name}`} alt={"image1"} style={{height: 150,maxWidth: 300}}/>
		</div>
		<Grid container >       
			<Grid item xs={4} sm={4}>
				<Paper className={classes.paper} style={{backgroundColor: "honeydew", maxHeight: paperHight}}>
					<p className={classes.paperContent}>
						<div style={{fontSize: 12}}>直近の気温</div>
						<div style={{color: "green", fontSize: 20,height: 30, lineHeight: "30px"}}><strong>{garden.current_temperature["気温"]}℃</strong></div>            
					</p>
				</Paper>
			</Grid>
			
			<Grid item xs={4} sm={4}>
				<Paper className={classes.paper} style={{backgroundColor: "aliceblue", maxHeight: paperHight}}>
					<p className={classes.paperContent}>
						<div style={{fontSize: 12}}>積算気温</div>
						<div style={{color: "blue", fontSize: 20,height: 30, lineHeight: "30px"}}><strong>{garden.current_cumulative_temperature && garden.current_cumulative_temperature["積算気温"]}℃</strong></div>            
					</p>
				</Paper>
			</Grid>

			<Grid item xs={4} sm={4}>
				<Paper className={classes.paper} style={{backgroundColor: "honeydew", maxHeight: paperHight}}>
					<p className={classes.paperContent}>
					<div style={{fontSize: 12}}>開孔面積率</div>
						<div style={{color: "green", fontSize: 20,height: 30, lineHeight: "30px"}}><strong>{garden.current_aperture && garden.current_aperture["開孔面積率"]}%</strong></div>            

					</p>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={12}> 
			<div style={{fontSize: 10}}>{moment(garden.current_temperature["date"]).format("MM/DD H:mm")}現在</div>
			</Grid>
		</Grid>

		<List dense={true}>
			<ListItem>
				<ListItemText	primary="圃場名" />{garden.name}
			</ListItem>
			<ListItem>
				<ListItemText	primary="栽培者" />{garden.farmer}
			</ListItem>
			<ListItem>
				<ListItemText	primary="作付面積" />{garden.area}a
			</ListItem>
		</List>
  </div>
	)
}

const GoogleMap = () => {
  const [gardensData, setGeardensData] = useState(null);

	function attachSecretMessage(maps, marker, garden, index) {
		const infowindow = new maps.InfoWindow({
			content: `<div id="infoWindow${index}" />`,
			minWidth: 300,
		});
		marker.addListener("click", () => {
			infowindow.addListener('domready', e => {
				ReactDOM.render( (<MarkerDom garden = {garden} ></MarkerDom>), document.getElementById(`infoWindow${index}`))
			})
			infowindow.open(marker.get("map"), marker);
		});
	}	
	const handleApiLoaded = ({ map, maps }) => {
		const bounds = new maps.LatLngBounds();
		gardensData.forEach((garden,index) => {
			var coordinates = garden.coordinates.split(', ')
			const marker = new maps.Marker({
				position: {
					lat: Number(coordinates[0]),
					lng: Number(coordinates[1]),
				},
				map,
				icon: {
					path: faCarrot.icon[4],
					fillColor: garden.status=== 1 ? "#fd7e14": "silver",
					fillOpacity: 1,
					anchor: maps.Point(
						faCarrot.icon[0] / 2, // width
						faCarrot.icon[1] // height
					),
					strokeWeight: 0,
					strokeColor: "#ffffff",
					scale: 0.075,
				},
				title: garden.name,
			});
			attachSecretMessage(maps, marker, garden, index-1);
			
			bounds.extend(marker.position);
		});
		map.fitBounds(bounds);
	};

	useEffect(() => {
		async function fetchData() {
      const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
      };

			await fetch(`${process.env.GATSBY_BACKEND_URL}/gardens`, options)
        .then(response => response.json())
        .then(data => {
          setGeardensData(data)
					data.forEach((garden) => {
						var coo = garden.coordinates.split(', ')
          	//console.log('緯度'+coo[0])
						//console.log('軽度'+coo[1])
					})
        })    
        .catch(error => {
          console.log(error)
          alert(error)
        })
    }
		fetchData();
	},[])

  return (
  <div style={{ height: '70vh', width: '100%' }}>
    {gardensData && (<GoogleMapReact
      bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_API}` }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      onGoogleApiLoaded={handleApiLoaded}
    />)}
  </div>
)
	}

export default GoogleMap
